import { MEMBER, OFFICER } from 'constants/roles.constant'

const appConfig = {
  backendURL: 'https://bicollodge-5ewj3siqfq-as.a.run.app',
  apiPrefix: `https://bicollodge-5ewj3siqfq-as.a.run.app/api`,
  authenticatedEntryPath: {
    [OFFICER]: '/lodges',
    [MEMBER]: '/getting-started',
  },
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: true,
}

export default appConfig
