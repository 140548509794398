import { atom } from 'jotai'

export const messagesAtom = atom({
  userCreated:
    'Your account has been created. An email has been sent to your email address. Please check your email to verify your account.',
  userNotVerifiedTop:
    'You have not yet verified your account. Please follow the instructions in the email we sent you.',
  userNotVerifiedBottom: 'If you have not received the email you may click the button below to resend the email.',
  emailResent: 'Email has been re-sent. Please check your email to verify your account.',
  emailConfirmed: 'Your email has been confirmed! You may now sign in.',
  passwordReset: 'Your password has been reset! You may now sign in.',
})
