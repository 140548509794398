import { useState } from 'react'
import { HiDocumentText } from 'react-icons/hi'
import { saveAs } from 'file-saver'
import classNames from 'classnames'

import { humanFileSize } from 'utils/strapi'
import { Dialog, toast, Notification } from 'components/ui'
import useTwColorByName from 'utils/hooks/useTwColorByName'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import PDFDocumentPreview from './PDFDocumentPreview'
import ImagePreview from './ImagePreview'

const AssignmentFileItem = ({ file }) => {
  const color = useTwColorByName()
  const [dialogOpen, setDialogOpen] = useState(false)

  const downloadFile = () => {
    let isFileSaverSupported = true
    try {
      isFileSaverSupported = !!new Blob()
    } catch (e) {}
    if (!isFileSaverSupported)
      toast.push(
        <Notification title="Download error" type="danger" duration={5000} closable>
          Your browser does not support file download. Please try again with another browser.
        </Notification>
      )

    saveAs(file.url, file.name)
  }

  return (
    <>
      <div
        key={file.id.toString()}
        className="flex items-center justify-between rounded-md mb-2 p-2 hover:bg-gray-50 dark:hover:bg-gray-600/40 cursor-pointer user-select"
        onClick={() => setDialogOpen(true)}
      >
        <div className="flex items-center gap-3">
          <div
            className={classNames(
              'rounded-lg h-10 w-10 text-lg flex items-center justify-center',
              color(file.name),
              'text-gray-100'
            )}
          >
            <HiDocumentText />
          </div>
          <div>
            <h6 className="text-sm font-bold">{file.name}</h6>
            <p>{humanFileSize(file.size)}</p>
          </div>
        </div>
      </div>
      <Dialog
        isOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
        onRequestClose={() => setDialogOpen(false)}
        bodyOpenClassName="overflow-hidden"
        contentClassName={file.mime === 'application/pdf' || file.mime.includes('image/') ? 'w-fit' : ''}
        width={file.mime === 'application/pdf' ? 'min-content' : 520}
        closable
      >
        <div className={file.mime === 'application/pdf' ? 'flex flex-col h-full justify-between' : ''}>
          <div className="mb-4">
            <h5>Preview</h5>
            <p>
              {file.name} (
              <span className="text-blue-500 underline cursor-pointer" onClick={downloadFile}>
                Download
              </span>
              )
            </p>
          </div>
          {file.mime.includes('application/') && file.mime === 'application/pdf' && <PDFDocumentPreview file={file} />}
          {file.mime.includes('application/') && file.mime !== 'application/pdf' && 'This document cannot be preview.'}
          {file.mime.includes('image/') && <ImagePreview file={file} />}
        </div>
      </Dialog>
    </>
  )
}

export default AssignmentFileItem
