import ApiService from './ApiService'

export async function apiCreateDocument(data) {
  return ApiService.fetchData({
    url: '/documents',
    method: 'post',
    headers: { 'Content-Type': 'multipart/form-data' },
    data,
  })
}
