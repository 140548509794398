import { useEffect, useState } from 'react'
import { Select, Spinner } from 'components/ui'
import { CustomSelectOption } from 'components/shared'
import { components } from 'react-select'
import _ from 'lodash'
import { getMonthName } from 'utils/getMonthName'
import { apiGetReportList } from 'services/ReportService'
import { useQuery } from '@tanstack/react-query'
import { useAssignmentsStore } from 'stores/useAssignmentsStore'

const { Control } = components

const CustomControl = ({ children, ...props }) => {
  return <Control {...props}>{children}</Control>
}

const DateFilter = () => {
  const selectedTab = useAssignmentsStore((state) => state.selectedTab)
  const selectedReport = useAssignmentsStore((state) => state.selectedReport)
  const setSelectedReport = useAssignmentsStore((state) => state.setSelectedReport)

  const [yearOptions, setYearOptions] = useState(null)
  const [monthOptions, setMonthOptions] = useState(null)

  const { data: reports, isFetching } = useQuery(
    ['reports', selectedTab.toLowerCase()],
    async () => {
      const response = await apiGetReportList({ 'filters[status]': selectedTab.toLowerCase() })
      return response.data.data
    },
    { retry: false, staleTime: 100 * (60 * 1000), cacheTime: 100 * (60 * 1000) }
  )

  const onSelectedYearChange = (selected) => {
    const report = _.find(
      reports,
      (report) =>
        report.attributes.month === selectedReport.attributes.month &&
        report.attributes.year === selected.value &&
        report.attributes.status === selectedTab.toLowerCase()
    )
    setSelectedReport(report)
  }

  const onSelectedMonthChange = (selected) => {
    const report = _.find(
      reports,
      (report) =>
        report.attributes.month === selected.value &&
        report.attributes.year === selectedReport.attributes.year &&
        report.attributes.status === selectedTab.toLowerCase()
    )
    setSelectedReport(report)
  }

  useEffect(() => {
    if (isFetching) return
    if (!reports || reports.length === 0) return
    let groupedReports = _.filter(reports, (report) => report.attributes.status === selectedTab.toLowerCase())
    groupedReports = _.groupBy(groupedReports, 'attributes.year')

    let report = selectedReport
    if (!selectedReport || report.attributes.status !== selectedTab) {
      const years = []
      Object.keys(groupedReports).forEach((year) => years.push(parseInt(year)))
      report = _.maxBy(groupedReports[_.max(years)], 'id')
    }

    const yearOptions = []
    Object.keys(groupedReports).forEach((year) => {
      yearOptions.push({ value: parseInt(year), label: year })
    })
    yearOptions.sort((a, b) => b.value - a.value)

    const monthOptions = []
    groupedReports[report.attributes.year].forEach((report) => {
      const month = parseInt(report.attributes.month)
      monthOptions.push({ value: month, label: getMonthName(month - 1) })
    })
    monthOptions.sort((a, b) => b.value - a.value)

    setYearOptions(yearOptions)
    setMonthOptions(monthOptions)
    setSelectedReport(report)
  }, [isFetching, reports, selectedTab, selectedReport, setSelectedReport])

  if (isFetching || !selectedReport) return <Spinner />

  return (
    <>
      <Select
        options={monthOptions}
        size="sm"
        className="min-w-[130px]"
        onChange={onSelectedMonthChange}
        components={{
          Option: CustomSelectOption,
          Control: CustomControl,
        }}
        value={monthOptions?.filter((option) => option.value === selectedReport.attributes.month)}
      />
      <Select
        options={yearOptions}
        size="sm"
        className="min-w-[130px]"
        onChange={onSelectedYearChange}
        components={{
          Option: CustomSelectOption,
          Control: CustomControl,
        }}
        value={yearOptions?.filter((option) => option.value === selectedReport.attributes.year)}
      />
    </>
  )
}

export default DateFilter
