import React from 'react'
import { Tabs } from 'components/ui'
import { reportStatus } from 'constants/api.constant'

const { TabNav, TabList } = Tabs

const ReportStatusTabs = ({ selectedTab, onChangeTab }) => {
  return (
    <Tabs value={selectedTab} variant="pill" onChange={(val) => onChangeTab(val)}>
      <TabList>
        {reportStatus.map((status) => (
          <TabNav key={status.value} value={status.value}>
            <span className="text-2xl mr-1">{status.icon}</span>
            {status.label}
          </TabNav>
        ))}
      </TabList>
    </Tabs>
  )
}

export default ReportStatusTabs
