import { useEffect, useState } from 'react'
import { Button, Dialog, FormItem, FormContainer, Select } from 'components/ui'
import { Form, Formik } from 'formik'
import { string, object } from 'yup'
import { apiGetPreDefinedAssignments, apiPutAssignment } from 'services/AssignmentService'
import useQuery from 'utils/hooks/useQuery'
import { useMutation, useQueryClient, useQuery as useReactQuery } from '@tanstack/react-query'
import CreatableSelect from 'react-select/creatable'
import { useAssignmentsStore } from 'stores/useAssignmentsStore'

const validationSchema = object().shape({
  name: string().required('Please enter a name.'),
})

const AddDialog = () => {
  const query = useQuery()
  const queryClient = useQueryClient()
  const [nameOptions, setNameOptions] = useState([])

  const isAddDialogOpen = useAssignmentsStore((state) => state.isAddDialogOpen)
  const closeAddDialog = useAssignmentsStore((state) => state.closeAddDialog)
  const selectedReport = useAssignmentsStore((state) => state.selectedReport)

  const { mutate, isLoading: addingAssignment } = useMutation(async (values) => {
    const lodge = query.get('id')
    const data = { name: values.name, lodge: query.get('id'), report: selectedReport?.id }
    data.forDistrict = lodge ? false : true
    if (lodge) data.lodge = lodge

    const response = await apiPutAssignment(data, { populate: ['comments', 'documents'] })
    queryClient.invalidateQueries(['assignments', selectedReport?.id, query.get('id')])
    closeAddDialog()
    return response
  })

  const onDialogClose = () => {
    if (addingAssignment) return
    closeAddDialog()
  }

  const onSubmit = async (values) => {
    mutate(values)
  }

  const { data, isLoading } = useReactQuery(
    ['pre-defined-assignments'],
    async () => await apiGetPreDefinedAssignments(),
    {
      retry: false,
      staleTime: 100 * (60 * 1000),
      cacheTime: 100 * (60 * 1000),
    }
  )

  useEffect(() => {
    if (!data) return
    const predefinedAssignments = data.data
    const options = predefinedAssignments.map(({ attributes: { title } }) => ({ value: title, label: title }))
    setNameOptions(options)
  }, [data])

  if (isLoading) return ''

  return (
    <Dialog isOpen={isAddDialogOpen} onClose={onDialogClose} onRequestClose={onDialogClose}>
      <h4>New Assignment</h4>
      <div className="mt-6">
        <Formik initialValues={{ name: '' }} validationSchema={validationSchema} onSubmit={onSubmit}>
          {({ touched, errors, setValues }) => (
            <Form>
              <FormContainer>
                <FormItem
                  label="Assignment Name"
                  invalid={errors.name && touched.name}
                  name="name"
                  errorMessage={errors.name}
                >
                  <Select
                    isClearable
                    onChange={(newValue, actionMeta) => {
                      if (actionMeta.action === 'clear') setValues({ name: '' })
                      if (!newValue) return
                      setValues({ name: newValue.value })
                    }}
                    placeholder="Type something..."
                    componentAs={CreatableSelect}
                    options={nameOptions}
                  />
                </FormItem>
                <FormItem className="mb-0 text-right">
                  <Button block variant="solid" type="submit" disabled={addingAssignment} loading={addingAssignment}>
                    {addingAssignment ? 'Creating...' : 'Add'}
                  </Button>
                </FormItem>
              </FormContainer>
            </Form>
          )}
        </Formik>
      </div>
    </Dialog>
  )
}

export default AddDialog
