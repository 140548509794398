import React from 'react'
import { Skeleton } from 'components/ui'

const AssignmentsLoading = () => {
  return (
    <>
      <Skeleton height={160} className="rounded-md" />
      <Skeleton height={160} className="rounded-md" />
      <Skeleton height={160} className="rounded-md" />
      <Skeleton height={160} className="rounded-md" />
    </>
  )
}

export default AssignmentsLoading
