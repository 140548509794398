import { Card } from 'components/ui'
import useQuery from 'utils/hooks/useQuery'
import { HiOutlinePlus } from 'react-icons/hi'
import { useNavigate } from 'react-router-dom'
import { AddAssignmentDialog, AssignmentCard, AssignmentsLoading } from 'components/shared'
import { apiGetAssignmentsList } from 'services/AssignmentService'
import { useQuery as useReactQuery } from '@tanstack/react-query'
import { useAssignmentsStore } from 'stores/useAssignmentsStore'
import { useSelector } from 'react-redux'
import { getMonthName } from 'utils/getMonthName'

const List = () => {
  const query = useQuery()
  const navigate = useNavigate()

  const lodge = query.get('id')
  const user = useSelector((state) => state.auth.user)
  const selectedTab = useAssignmentsStore((state) => state.selectedTab)
  const selectedReport = useAssignmentsStore((state) => state.selectedReport)
  const openAddDialog = useAssignmentsStore((state) => state.openAddDialog)

  const { data: assignments, isFetching } = useReactQuery(
    ['assignments', selectedReport?.id, lodge],
    async () => {
      const params = {
        'filters[report]': selectedReport?.id,
        'filters[forDistrict]': lodge ? false : true,
        populate: ['comments', 'documents'],
      }
      if (window.location.pathname === '/assignment/list') params['filters[forDistrict]'] = false
      if (lodge) params['filters[lodge]'] = lodge

      const response = await apiGetAssignmentsList(params)
      return response.data.data
    },
    { retry: false }
  )

  return (
    <>
      <div className="mt-4 grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
        {(isFetching || !selectedReport) && <AssignmentsLoading />}
        {!isFetching && selectedReport && (
          <>
            {assignments?.map((assignment) => (
              <AssignmentCard
                key={assignment.id}
                data={assignment}
                onClick={() => {
                  navigate(
                    user.role.type === 'authenticated'
                      ? `/lodge/assignment?id=${assignment.id}`
                      : `/member/assignment?id=${assignment.id}`
                  )
                }}
              />
            ))}
            {user?.role.type === 'authenticated' && selectedTab === 'active' && (
              <Card
                key="add-assignment"
                className="border-dashed border-2 hover:border-indigo-600 hover:dark:border-gray-300 bg-transparent"
                clickable
                onClick={openAddDialog}
              >
                <div className="flex flex-col justify-center items-center">
                  <div className="p-4 rounded-full bg-gray-50 dark:bg-gray-600">
                    <HiOutlinePlus className="text-4xl text-gray-300" />
                  </div>
                  <p className="mt-5 font-semibold">Add Assignment</p>
                </div>
              </Card>
            )}
          </>
        )}
      </div>
      {user?.role.type === 'authenticated' && <AddAssignmentDialog />}
      {user?.role.type === 'member' && !isFetching && assignments?.length === 0 && (
        <h5 className="text-center">
          No assignments found for {getMonthName(selectedReport?.attributes.month - 1)}
          &nbsp;{selectedReport?.attributes.year}.
        </h5>
      )}
    </>
  )
}

export default List
