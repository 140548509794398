import ApiService from './ApiService'

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: '/auth/local',
    method: 'post',
    data,
  })
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: '/auth/local/register',
    method: 'post',
    data: { ...data, username: data.email },
  })
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: '/auth/forgot-password',
    method: 'post',
    data,
  })
}

export async function apiChangePassword(data) {
  return ApiService.fetchData({
    url: '/auth/change-password',
    method: 'post',
    data,
  })
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: '/auth/reset-password',
    method: 'post',
    data,
  })
}

export async function apiResendEmailVerification(data) {
  return ApiService.fetchData({
    url: '/auth/send-email-confirmation',
    method: 'post',
    data: { ...data },
  })
}

export async function apiVerifyEmail(params) {
  return ApiService.fetchData({
    url: '/auth/email-confirmation',
    method: 'get',
    params,
  })
}

export async function apiGetMyInfo(params) {
  return ApiService.fetchData({
    url: '/users/me',
    method: 'get',
    params,
  })
}

export async function apiUpdateUserStatus(id, data) {
  return ApiService.fetchData({
    url: `/users/${id}`,
    method: 'put',
    data,
  })
}
