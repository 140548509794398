import classNames from 'classnames'
import { Card, Tag } from 'components/ui'
import IconText from 'components/shared/IconText'
import { HiOutlineChatAlt2, HiOutlinePaperClip, HiFlag } from 'react-icons/hi'
import dayjs from 'dayjs'
import { ucwords } from 'utils/ucwords'
import { assignmentStatus } from 'constants/api.constant'

const AssignmentCard = (props) => {
  const { listId, cardData, data, onClick, cardControl, ...rest } = props

  const { name, comments, status, createdAt, documents } = data.attributes

  return (
    <Card className={classNames('rounded-lg dark:bg-gray-700 bg-white-500')} bodyClass="p-4" {...rest}>
      <div className="flex justify-between">
        {status && (
          <Tag
            key={status}
            className="mr-2 rtl:ml-2 mb-2"
            prefix
            prefixClass={`${assignmentStatus.find((item) => item.value === status)?.color}`}
          >
            {ucwords(status)}
          </Tag>
        )}
      </div>
      <h6 className="mb-2 cursor-pointer" onClick={onClick}>
        {name}
      </h6>
      {createdAt && (
        <IconText textClass="text-sm font-semibold" className="mb-2" icon={<HiFlag className="text-lg" />}>
          {dayjs(new Date(createdAt)).format('MMM DD, YYYY')}
        </IconText>
      )}
      <div className="flex items-center justify-between mt-3">
        <div className="flex items-center gap-2">
          {comments?.data?.length > 0 && (
            <IconText className="font-semibold" icon={<HiOutlineChatAlt2 className="text-base" />}>
              {comments.data.length}
            </IconText>
          )}
          {documents?.data?.length > 0 && (
            <IconText icon={<HiOutlinePaperClip />} className="text-base">
              {documents.data.length}
            </IconText>
          )}
        </div>
      </div>
    </Card>
  )
}

export default AssignmentCard
