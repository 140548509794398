import { HiOutlineArchive, HiOutlineDocumentText } from 'react-icons/hi'

export const TOKEN_TYPE = 'Bearer '
export const REQUEST_HEADER_AUTH_KEY = 'Authorization'

export const reportStatus = [
  { label: 'Active', value: 'active', icon: <HiOutlineDocumentText /> },
  { label: 'Archived', value: 'archived', icon: <HiOutlineArchive /> },
]

export const assignmentStatus = [
  { label: 'Pending', value: 'pending', color: 'bg-gray-400' },
  { label: 'Approved', value: 'approved', color: 'bg-green-500' },
  { label: 'Denied', value: 'denied', color: 'bg-red-500' },
]

export const userStatus = [
  { value: 'pending', label: 'Pending', color: 'bg-gray-400' },
  { value: 'active', label: 'Active', color: 'bg-emerald-500' },
  { value: 'suspended', label: 'Suspended', color: 'bg-yellow-500' },
  { value: 'expelled', label: 'Expelled', color: 'bg-red-500' },
  { value: 'deceased', label: 'Deceased', color: 'bg-gray-500' },
]

export const roleOptions = [
  { value: 1, label: 'Officer' },
  { value: 4, label: 'Member' },
]
