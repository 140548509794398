import React from 'react'
import { Field } from 'formik'
import { FormItem, Upload } from 'components/ui'
import { getSmallFormat } from 'utils/strapi'

const ImageUploadField = (props) => {
  const { label, name, children, touched, errors } = props

  const onSetFormFile = (form, field, file) => {
    form.setFieldValue(field.name, file[0])
  }

  return (
    <FormItem label={label} invalid={errors[name] && touched[name]} errorMessage={errors[name]}>
      <Field name={name}>
        {({ field, form }) => (
          <Upload
            draggable
            className="cursor-pointer h-[300px]"
            onChange={(files) => onSetFormFile(form, field, files)}
            onFileRemove={(files) => onSetFormFile(form, field, files)}
            showList={false}
            uploadLimit={1}
          >
            {field.value && field.value instanceof File && (
              <img className="p-3 max-h-[300px]" src={URL.createObjectURL(field.value)} alt="" />
            )}
            {field.value && typeof field.value === 'string' && (
              <img className="p-3 max-h-[300px]" src={getSmallFormat(JSON.parse(field.value))} alt="" />
            )}
            {!field.value && (
              <div className="text-center">
                {children}
                <p className="font-semibold">
                  <span className="text-gray-800 dark:text-white">Drop your {label} here, or </span>
                  <span className="text-blue-500">browse</span>
                </p>
                <p className="mt-1 opacity-60 dark:text-white">Supported: jpeg, png</p>
              </div>
            )}
          </Upload>
        )}
      </Field>
    </FormItem>
  )
}

export default ImageUploadField
