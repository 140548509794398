import { useState } from 'react'
import { pdfjs, Document, Page } from 'react-pdf'

const url = `//cdn.jsdelivr.net/npm/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`
pdfjs.GlobalWorkerOptions.workerSrc = url

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
}

const PDFDocumentPreview = ({ file }) => {
  const [numPages, setNumPages] = useState(null)

  return (
    <div className="max-h-[75vh] min-w-[30vw] overflow-y-auto">
      <div className="text-center">
        <Document file={`${file.url}`} options={options} onLoadSuccess={({ numPages }) => setNumPages(numPages)}>
          {Array.from(new Array(numPages), (el, index) => (
            <Page key={index} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </div>
  )
}

export default PDFDocumentPreview
