import ApiService from './ApiService'

export async function apiGetReportList(params) {
  return ApiService.fetchData({
    url: '/reports',
    method: 'get',
    params,
  })
}

export async function apiGetReportAssignments(id) {
  return ApiService.fetchData({
    url: `/reports/${id}?populate=assignments`,
    method: 'get',
  })
}
