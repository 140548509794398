import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiGetMyInfo, apiSignIn, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { messagesAtom } from 'constants/messages.constant'

function useAuth() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [messages] = useAtom(messagesAtom)

  const { token, signedIn } = useSelector((state) => state.auth.session)

  const signIn = async (values) => {
    try {
      const signInResponse = await apiSignIn(values)
      if (signInResponse?.data?.jwt) {
        const { jwt, user } = signInResponse.data
        if (!['pending', 'active'].includes(user.status)) {
          navigate('/access-denied')
          return
        }
        dispatch(onSignInSuccess(jwt))

        const { data: userData } = await apiGetMyInfo({
          populate: ['role', 'userInfo.picture', 'userInfo.duesCard', 'userInfo.lodge.logo'],
        })

        if (
          userData?.status === 'pending' &&
          userData?.userInfo?.apprenticeDate &&
          userData?.userInfo?.masterDegreeDate &&
          userData?.userInfo?.fellowcraftDate
        ) {
          navigate('/member-not-approved')
          dispatch(onSignOutSuccess())
          return
        }

        if (userData) dispatch(setUser(userData))
        return { status: 'success', userData }
      }
    } catch (errors) {
      if (errors.code === 'ERR_NETWORK') return errors
      else if (errors.response?.data?.error?.name === 'ApplicationError')
        return {
          status: errors.response.data.error.name,
          top: messages.userNotVerifiedTop,
          bottom: messages.userNotVerifiedBottom,
          email: values.identifier,
        }
      return {
        status: 'failed',
        message: errors?.response?.data?.error?.message || errors.toString(),
      }
    }
  }

  const signUp = async (values) => {
    try {
      const response = await apiSignUp(values)
      if (response.status === 200) {
        return {
          status: 'success',
          message: messages.userCreated,
        }
      }
    } catch (errors) {
      console.error('errors: ', errors)
      if (errors.response?.data?.error?.name === 'ApplicationError') {
        return {
          status: 'field-error',
          field: 'email',
          message: 'Email is already taken.',
        }
      }
      return {
        status: 'failed',
        message: errors?.response?.data?.message || errors.toString(),
      }
    }
  }

  const handleSignOut = () => {
    dispatch(onSignOutSuccess())
    dispatch(setUser(initialState))
    navigate(appConfig.tourPath)
  }

  const signOut = () => {
    handleSignOut()
  }

  return {
    authenticated: token && signedIn,
    signIn,
    signUp,
    signOut,
  }
}

export default useAuth
