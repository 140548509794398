import { nameAcronym } from './acronym'
import orderBy from 'lodash/orderBy'

export const getThumbnail = (image) => {
  const thumbnail = image?.formats?.thumbnail
  return thumbnail ? thumbnail.url : image?.url
}

export const getSmallFormat = (image) => {
  const small = image?.formats?.small
  return small ? small.url : image?.url
}

export const getLargeFormat = (image) => {
  const large = image?.formats?.large
  return large ? large.url : image?.url
}

export const dateToSQLValue = (date) => {
  if (!date) return null

  let formattedDate = `${date.getFullYear()}-`
  formattedDate += date.getMonth() + 1 < 10 ? `0` : ''
  formattedDate += `${date.getMonth() + 1}-`
  formattedDate += date.getDate() < 10 ? `0` : ''
  formattedDate += `${date.getDate()}`

  return formattedDate
}

export const generateActivities = (assignment) => {
  let activities = []
  if (!assignment) return activities

  const { requester } = assignment
  activities.push({
    type: 'CREATE-ASSIGNMENT',
    date: assignment?.createdAt,
    user: {
      name: `${requester.firstName} ${nameAcronym(requester.middleName)} ${requester.lastName}`,
      picture: getThumbnail(requester.picture),
    },
  })

  assignment.comments?.forEach((comment) => {
    const { commenter } = comment

    activities.push({
      type: 'COMMENT',
      date: comment.createdAt,
      comment: comment.message,
      user: {
        name: `${commenter.firstName} ${nameAcronym(commenter.middleName)} ${commenter.lastName}`,
        picture: getThumbnail(commenter.picture),
      },
    })
  })

  assignment.documents?.forEach((document) => {
    const { file, uploader } = document

    activities.push({
      type: 'ADD-FILES-TO-REPORT',
      date: document.createdAt,
      file: file.name,
      user: {
        name: `${uploader.firstName} ${nameAcronym(uploader.middleName)} ${uploader.lastName}`,
        picture: getThumbnail(uploader.picture),
      },
    })
  })

  if (assignment.dateApproved && assignment.approver) {
    const { approver } = assignment
    activities.push({
      type: 'UPDATE-REPORT-STATUS',
      date: assignment.dateApproved,
      status: assignment.status,
      user: {
        name: `${approver.firstName} ${nameAcronym(approver.middleName)} ${approver.lastName}`,
        picture: getThumbnail(approver.picture),
      },
    })
  }

  activities = orderBy(activities, ['date'], ['asc'])

  return activities
}

export const humanFileSize = (kilobytes, dp = 2) => {
  const thresh = 1000

  if (Math.abs(kilobytes) < thresh) {
    return kilobytes + ' kB'
  }

  const units = ['MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  let u = -1
  const r = 10 ** dp

  do {
    kilobytes /= thresh
    ++u
  } while (Math.round(Math.abs(kilobytes) * r) / r >= thresh && u < units.length - 1)

  return kilobytes.toFixed(dp) + ' ' + units[u]
}

export const getHighlightedText = (text, highlight) => {
  if (!highlight) return text
  // Split on highlight term and include term into parts, ignore case
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'))
  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span key={i} style={part.toLowerCase() === highlight.toLowerCase() ? { backgroundColor: 'yellow' } : {}}>
          {part}
        </span>
      ))}{' '}
    </span>
  )
}
