import { create } from 'zustand'

export const useAssignmentsStore = create((set) => ({
  isAddDialogOpen: false,
  openAddDialog: () => set({ isAddDialogOpen: true }),
  closeAddDialog: () => set({ isAddDialogOpen: false }),

  selectedReport: null,
  setSelectedReport: (selectedReport) => set({ selectedReport }),

  selectedTab: 'active',
  setSelectedTab: (selectedTab) => set({ selectedTab }),
  selectedYear: new Date().getFullYear(),
  setSelectedYear: (selectedYear) => set({ selectedYear }),
  selectedMonth: new Date().getMonth(),
  setSelectedMonth: (selectedMonth) => set({ selectedMonth }),
}))
