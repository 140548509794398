const ImagePreview = ({ file }) => {
  return (
    <div className="max-h-[75vh] min-w-[30vw] overflow-y-auto">
      <div className="text-center">
        <img alt={file.name} src={`${file.url}`} />
      </div>
    </div>
  )
}

export default ImagePreview
